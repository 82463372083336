import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  contract_id: null,
  paroject_id: null,
  email: "",
  mainService_id: null,
  password: "",
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, { payload }) => {
      if (state.items.find((it) => it.id === payload.id)) {
        state.items = state.items.filter((it) => it.id !== payload.id);
        state.items.push(payload);
      } else {
        state.items.push(payload);
        state.items = current(state.items);
      }
    },
    addContract_id: (state, { payload }) => {
      state.contract_id = payload;
    },
    addParoject_id: (state, { payload }) => {
      state.paroject_id = payload;
    },
    add_email: (state, { payload }) => {
      state.email = payload;
    },
    add_mainService_id: (state, { payload }) => {
      state.mainService_id = payload;
    },
    add_password: (state, { payload }) => {
      state.password = payload;
    },
  },
});

export const {
  addToCart,
  addContract_id,
  addParoject_id,
  add_email,
  add_mainService_id,
  add_password,
} = cartSlice.actions;

export default cartSlice.reducer;
