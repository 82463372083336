import { Row, Col, message } from "antd";
import LoginForm from "./form";
import { FormContainer, LoginContainer, SignInText, FillYour } from "./style";
import { useSelector } from "react-redux";
import customFetch from "../../services/api";

const Otp = () => {
  const email = useSelector((state) => state.cart.email);
  const password = useSelector((state) => state.cart.password);

  const onSendAgain = async () => {
    try {
      const response = await customFetch("admin/login", {
        body: { email, password },
        method: "POST",
      });
      if (response.status) {
        message.success("code sent again");
      } else {
        message.error("Password or Email is invalid");
      }
    } catch (error) {
      message.error("Password or Email is invalid");
    }
  };
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Row
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ width: "50%" }} className="left-col">
          <LoginContainer>
            <FormContainer
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="logo.svg" alt="logo" />
              <SignInText>OTP Verification </SignInText>
              <FillYour>Please enter the OTP Which sent to you!</FillYour>
              <LoginForm />
              <span className="no-otp">
                No OTP Sent? <span onClick={onSendAgain}>Send again</span>
              </span>
            </FormContainer>
          </LoginContainer>
        </Col>
        <Col xs={0} sm={12} style={{ height: "100%", width: "100%" }}>
          <img
            alt=""
            src="login-cover.jpeg"
            style={{
              height: "100%",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              width: "100%",
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Otp;
