import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  manpower: [],
  equipments: [],
  materials: [],
  vehicles: [],
};

export const assignServiceSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addManPower: (state, { payload }) => {
      if (state.manpower.find((obj) => obj == payload)) {
        state.manpower = state.manpower.filter((obj) => obj != payload);
      } else {
        state.manpower.push(payload);
      }
    },
    addEquipment: (state, { payload }) => {
      if (state.equipments.find((obj) => obj == payload)) {
        state.equipments = state.equipments.filter((obj) => obj != payload);
      } else {
        state.equipments.push(payload);
      }
    },
    addMaterial: (state, { payload }) => {
      if (state.materials.find((obj) => obj.id == payload.id)) {
        state.materials = state.materials.filter((obj) => obj.id != payload.id);
      } else {
        state.materials.push(payload);
      }
    },
    addVehicles: (state, { payload }) => {
      if (state.vehicles.find((obj) => obj == payload)) {
        state.vehicles = state.vehicles.filter((obj) => obj != payload);
      } else {
        state.vehicles.push(payload);
      }
    },
    clearServices: (state, payload) => {
      state.manpower = [];
      state.materials = [];
      state.equipments = [];
      state.vehicles = [];
    },
  },
});

export const {
  addManPower,
  addEquipment,
  addMaterial,
  addVehicles,
  clearServices,
} = assignServiceSlice.actions;

export default assignServiceSlice.reducer;
