import { Button, Form, message } from "antd";
import { InputOTP } from "antd-input-otp";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import customFetch from "../../services/api";

const LoginForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const email = useSelector((state) => state.cart.email);
  const handleFinish = async (values) => {
    if (!values.otp || values.otp.length < 4) {
      message.error("Invalid OTP!");
    } else {
      let otpString = values.otp.join("");
      try {
        const response = await customFetch("admin/check-otp", {
          body: { email: email, code: otpString },
          method: "POST",
        });
        if (response.status) {
          const token = response.data.access_token;
          const user = response.data;
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          navigate("/dashboard");
          window.location.reload();
        } else {
          message.error("OTP  is invalid");
        }
      } catch (error) {
        message.error("Password or Email is invalid");
      }
    }
  };
  return (
    <Form
      onFinish={handleFinish}
      form={form}
      layout="vertical"
      name="login-form"
      className="login-form"
    >
      <Form.Item name={"otp"}>
        <InputOTP inputType="numeric" length={4} classNames={"otp-field"} />
      </Form.Item>
      <Form.Item>
        <Button
          block
          htmlType="submit"
          style={{
            background: "#00A8C0",
            borderRadius: "6px",
            height: "55px",
            color: "#EFF4F8",
            fontWeight: "600",
            lineHeight: "14px",
            marginBottom: "17px",
          }}
        >
          Verify
        </Button>
      </Form.Item>
    </Form>
  );
};
export default LoginForm;
