const protectedPages = [
  { path: "dashboard" },
  { path: "order-track" },
  { path: "projects" },
  { path: "companies" },
  { path: "contracts" },
  { path: "complaints" },
  { path: "/complaints-details/:id", filePath: "complaints-details" },
  { path: "main-services" },
  { path: "services" },
  { path: "equipments" },
  { path: "vehicles" },
  { path: "warehouse-categories" },
  { path: "quantity-type-warehouse" },
  { path: "warehouse" },
  { path: "warehouse-detail" },
  { path: "edit-profile" },
  { path: "contracts-map" },
  { path: "manpower" },
  { path: "manpowers-position" },
  { path: "invoices" },
  { path: "contract-details" },
  { path: "admins" },
  { path: "announcements" },
  { path: "notification" },
  { path: "/assign-service/:id", filePath: "assign-services" },
  {
    path: "equipments/equipment-categories",
    filePath: "equipments/equipment-categories",
  },
  {
    path: "equipments/equipment-makes",
    filePath: "equipments/equipment-makes",
  },
  {
    path: "vehicles/vehicle-categories",
    filePath: "vehicles/vehicle-categories",
  },
  { path: "vehicles/vehicle-makes", filePath: "vehicles/vehicle-makes" },
  { path: "vehicles/vehicle-models/:id", filePath: "vehicles/vehicle-models" },
  { path: "/company/:id", filePath: "company-users" },
  {
    path: "equipments/equipment-models/:id",
    filePath: "equipments/equipment-models",
  },
];
const requestView = { path: "/view/request/:id", filePath: "order-track" };
const vehicleView = { path: "/view/vehicle/:id", filePath: "vehicles/vehicle" };
const warehouseView = {
  path: "/view/warehouse/:id",
  filePath: "warehouse/warehouse-detail",
};
const equipmentView = {
  path: "/view/equipment/:id",
  filePath: "equipments/equipment",
};

export const protectedRoutes = [
  requestView,
  vehicleView,
  equipmentView,
  warehouseView,
  ...protectedPages.map((p) => ({
    path: p.path,
    filePath: p.filePath ? p.filePath : p.path,
  })),
];
