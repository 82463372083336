import { Row, Col, message } from "antd";
import LoginForm from "./form";
import { FormContainer, LoginContainer, SignInText, FillYour } from "./style";
import "./login.css";
import customFetch from "../../services/api";
import { useNavigate } from "react-router-dom";
import { add_email, add_password } from "../../rdx/features/cartSlice";
import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    try {
      const response = await customFetch("admin/login", {
        body: values,
        method: "POST",
      });
      if (response.status) {
        dispatch(add_email(values.email));
        dispatch(add_password(values.password));

        navigate("/verify");
      } else {
        message.error("Password or Email is invalid");
      }
    } catch (error) {
      message.error("Password or Email is invalid");
    }
  };
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Row
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ width: "50%" }} className="left-col">
          <LoginContainer>
            <FormContainer
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="logo.svg" alt="logo" />
              <SignInText>Sign In for an Account</SignInText>
              <FillYour>Fill your Data carefully!</FillYour>
              <LoginForm onLogin={onFinish} />
            </FormContainer>
          </LoginContainer>
        </Col>
        <Col xs={0} sm={12} style={{ height: "100%", width: "100%" }}>
          <img
            alt=""
            src="login-cover.jpeg"
            style={{
              height: "100%",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              width: "100%",
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Login;
