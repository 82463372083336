import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom";

const LoginForm = ({ onLogin }) => {
  return (
    <Form
      layout="vertical"
      name="login-form"
      onFinish={onLogin}
      className="login-form"
    >
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter your new password",
          },
        ]}
      >
        <Input
          type="password"
          placeholder="New Password"
          style={{ height: "45px", width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="password_confirmation"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The new password that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input
          placeholder="Confirm New Password"
          style={{ height: "45px", width: "100%" }}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          style={{
            background: "#00A8C0",
            borderRadius: "6px",
            height: "55px",
            color: "#EFF4F8",
            fontWeight: "600",
            lineHeight: "14px",
            marginTop: "20px",
          }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default LoginForm;
