import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom";

const LoginForm = ({ onLogin }) => {
  return (
    <Form
      layout="vertical"
      name="login-form"
      onFinish={onLogin}
      className="login-form"
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your Email",
          },
          {
            type: "text",
            message: "Please enter a validate Email!",
          },
        ]}
      >
        <Input placeholder="Email" style={{ height: "45px", width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password",
          },
        ]}
      >
        <Input.Password
          placeholder="Password"
          style={{ height: "45px", width: "100%" }}
        />
      </Form.Item>
      <div style={{ textAlign: "center" }}>
        <Link className="forget" to={"/forget-password"}>
          Forgot Password?
        </Link>
      </div>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          style={{
            background: "#00A8C0",
            borderRadius: "6px",
            height: "55px",
            color: "#EFF4F8",
            fontWeight: "600",
            lineHeight: "14px",
            marginTop: "20px",
          }}
        >
          Sign In
        </Button>
      </Form.Item>
      {/* { extra } */}
    </Form>
  );
};
export default LoginForm;
