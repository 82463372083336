// store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import cartReducer from "./features/cartSlice";
import serviceReducer from "./features/assignServiceSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedCartReducer = persistReducer(persistConfig, cartReducer);
const persistServiceReducer = persistReducer(persistConfig, serviceReducer);

const rootReducer = combineReducers({
  cart: persistedCartReducer,
  services: persistServiceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const persistor = persistStore(store);
