import styled from "styled-components";
export const LoginContainer = styled.div`
  background-color: var(--eff-4-f-8, #eff4f8);
  height: 100%;
  width: 100%;
`;
export const FormContainer = styled.div`
  width: 100%;
  height: 100vh; /* Set height to full viewport height */
  margin: 0 auto;
`;
export const SignInText = styled.p`
  color: var(--1-e-1-e-1-e, #1e1e1e);
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */
  margin: 30px auto 0;
`;
export const FillYour = styled.p`
  color: var(--Gray-Gray-500, #a1a5b7);
  text-align: center;
  margin: 15px auto 35px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;
